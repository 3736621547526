<template>
  <ListTablePanel
    v-if="isReady"
    :key="cid"
    :panel="panel"
    :mode="mode"
    :isEditing="isEditing"
    :items="items"
    :showSelected="equipment ? true : false"
    @selectDataCell="onSelectDataCell($event)"
  >
  </ListTablePanel>
</template>

<script>
import ListTablePanelBase from "@/components/list-table-panel-base.js";
export default {
  name: "ConnectorListTablePanel",
  extends: ListTablePanelBase,
  computed: {
    items() {
      return this.connectorList;
    }
  }
};
</script>

<style scoped>
.editor-toolbar {
  /* position: absolute; */
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
}

.editor-toolbar.editing {
  border-top-color: #f39c12;
}

.editor-toolbar > div {
  background-color: white;
}

.icon {
  margin: 0 5px;
}

.icon:hover {
  cursor: pointer;
  opacity: 0.8;
  text-shadow: 0px 0px 3px gray;
}

.cell-text {
  text-align: left;
}

.cell-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.group-status-info {
  font-size: 75%;
  padding: 0 10px;
}

.action-group:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #337ab7;
}
</style>
