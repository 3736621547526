<template>
  <section>
    <div
      :class="{
        'editor-toolbar': mode == 'editor',
        editing: isEditing
      }"
    >
      <slot name="toolbar"></slot>
    </div>
    <SearchableTable
      ref="searchableTable"
      :items="items"
      :fields="fields"
      :class="mode == 'editor' ? 'editing' : ''"
      :searchEnabled="panelOptions.searchEnabled"
      :pagination="pagination"
      :maxResult="items.length"
      :showSelected="showSelected"
      :aggregation="aggregation"
      :sortDef="sortDef"
      :persistent="false"
      @unselect="onSelect()"
      @groupsUpdated="groupsUpdated($event)"
    >
      <template
        v-for="field in fields"
        v-slot:[`group-cell-${field.name}`]="data"
      >
        <span
          :key="field.name"
          v-html="groupCell(data.nodeName, field)"
          :class="{
            'action-group': hasEnabledGroupActions(data.nodeName, field)
          }"
          @click="onGroupCellClick($event, data.nodeName, field)"
        />
      </template>
      <template v-for="(field, ix) in fields" v-slot:[`col${ix}`]="entry">
        <div
          :key="ix"
          class="cell-container"
          @click.stop.prevent="onSelectDataCell(entry.item, field)"
        >
          <div v-for="align in ['left', 'center', 'right']" :key="align">
            <span
              class="icon"
              v-for="(icon, ixl) in cellIcons(entry.item, field, align)"
              :key="`ixl${ixl}`"
              @click.prevent.stop="onIconClicked(entry.item, icon)"
              :title="icon.hint ? $tc(icon.hint, 1) : ''"
            >
              <template v-if="icon && icon.state">
                <b
                  :class="icon.state.class"
                  :style="{color: icon.state.color}"
                ></b>
              </template>
            </span>
            <span class="cell-text" v-if="align == 'left'">
              {{
                $refs.searchableTable
                  ? $refs.searchableTable.value(entry.item, field)
                  : ""
              }}
            </span>
          </div>
        </div>
      </template>
    </SearchableTable>
  </section>
</template>

<script>
import Panels from "@/assets/dashboard/panels.json";
import SearchableTable from "@/components/searchable-table.vue";
import {stats} from "@/components/control-sidebar/property-editors/detail-form-list-table.vue";

export default {
  name: "ListTablePanel",
  components: {
    SearchableTable
  },
  props: {
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    showSelected: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      statsByGroup: null
    };
  },
  computed: {
    pageSize() {
      return parseInt(
        (this.$root.config &&
          this.$root.config.equipment_selection &&
          this.$root.config.equipment_selection.page_size) ||
          0 // no pagination
      );
    },
    pagination() {
      return (
        this.items.length > this.pageSize && this.panelOptions.showPagination
      );
    },
    panelOptions() {
      return (
        this?.panel?.options ||
        Panels.find((panel) => panel.template.template == this.$options.name)
          .template.options
      );
    },
    columns() {
      return this?.panelOptions?.columns || [];
    },
    fields() {
      let self = this;
      let fields = [];
      this.columns.forEach((column, ix) => {
        let field = {
          name: `col${ix}`,
          title: column.title,
          visible: column.enabled,
          column: column,
          aggregation: column?.aggregation || false,
          sortable: !column?.aggregation?.enabled,
          parser(item) {
            if (column?.expression) {
              // console.log(item);
              return self.$utils.evaluate(item, column.expression);
            } else {
              // TODO: implement proxy of a COLUMN.parser function
              return item[column.name];
            }
          }
        };
        fields.push(field);
      });
      fields.push({
        name: `col${fields.length}`,
        visible: false,
        parser(i) {
          return i?.device?.has_active_alarms ?? i?.has_active_alarms ? 0 : 1;
        }
      });
      return fields;
    },
    panelStyle() {
      let style = JSON.parse(JSON.stringify(this?.panel?.options?.style || {}));
      if (this.isEditing) {
      }
      return style;
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    aggregation() {
      return this?.panel?.options?.aggregation || null;
    },
    groups() {
      return (
        this.fields.filter((f) => (f.aggregation.enabled ? true : false)) || []
      ).map(({name}) => name);
    },
    sortDef() {
      return {
        column: `col${this?.fields?.length - 1 || 0}`,
        asc: "true"
      };
    }
  },
  watch: {
    isEditing: {
      handler(n) {
        if (n) {
          if (this.statsByGroup) {
            this.$nextTick(() => {
              setTimeout(() => {
                this.updatePropertyForm();
              }, 10);
            });
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    icon(item, name) {
      return (item.icons || []).find((icon) => icon.name == name);
    },
    parseIconState(item, statusIcon) {
      if (!(item && statusIcon)) return null;
      switch (statusIcon.name) {
        case "connectorConnection":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item.is_connected
            ) || null
          );

        case "connectorEnabled":
          return (
            statusIcon.stateList.find(({value}) => value == item.enabled) ||
            null
          );

        case "connectorAlarmed":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item.has_active_alarms
            ) || null
          );

        case "connectorEditor":
          return statusIcon.stateList[0];

        case "deviceConnection":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item?.device?.is_connected || false
            ) || null
          );

        case "deviceEnabled":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item?.device?.enabled || false
            ) || null
          );
        case "deviceAlarmed":
          return (
            statusIcon.stateList.find(
              ({value}) => value == item?.device?.has_active_alarms || false
            ) || null
          );

        case "deviceEditor":
          return statusIcon.stateList[0];

        default:
          return null;
      }
    },
    parseIconHint(item, statusIcon) {
      if (!(item && statusIcon)) return null;
      switch (statusIcon.name) {
        case "connectorConnection":
          return item.is_connected ? "connected" : "disconnected";

        case "connectorEnabled":
          return item.enabled ? "enabled" : "disabled";

        case "connectorAlarmed":
          return item.has_active_alarms ? "alarmed" : "normal";

        case "connectorEditor":
          return "edit";

        case "deviceConnection":
          return item?.device?.is_connected ? "connected" : "disconnected";

        case "deviceEnabled":
          return item?.device?.enabled ? "enabled" : "disabled";

        case "deviceAlarmed":
          return item?.device?.has_active_alarms ? "alarmed" : "normal";

        case "deviceEditor":
          return "edit";

        default:
          return null;
      }
    },
    cellIcons(item, field, side) {
      let icons = side
        ? (field?.column?.icons || []).filter(({align}) => align == side)
        : field?.column?.icons;
      return icons.map((cfg) => {
        let icon = JSON.parse(JSON.stringify(cfg.statusIcon));
        icon.state = this.parseIconState(item, icon);
        icon.hint = this.parseIconHint(item, icon);
        return icon;
      });
    },
    groupCell(nodeName, field) {
      let value = "";
      if (!this.statsByGroup || !nodeName || !field) return value;
      if (this.statsByGroup[nodeName] && field?.aggregation?.expression) {
        let entry = {
          ...this.statsByGroup[nodeName],
          name: (nodeName || "").split(".").pop(),
          icons: {
            connected: "<i class='fa fa-link text-success'></i> ",
            disconnected: "<i class='fa fa-unlink text-danger'></i> ",
            alarm_on: "<i class='fa fa-bell text-danger'></i> ",
            alarm_off: "<i class='fa fa-bell' style='color:#3c8dbc'></i> ",
            enable: "<i class='fa fa-toggle-on text-success'></i> ",
            connector: "<i class='fa fa-plug' style='color:#3c8dbc'></i> ",
            device:
              "<i class='glyphicon glyphicon-hdd' style='color:#3c8dbc'></i> ",
            data:
              "<i class='glyphicon glyphicon-stats' style='color:#3c8dbc'></i> ",
            alarm: "",
            connectorConnection: "",
            deviceConnection: ""
          }
        };
        entry.icons.alarm = entry.stats.alarmedConnectors
          ? "<i class='fa fa-bell text-danger'></i> "
          : "<i class='fa fa-bell' style='color:#3c8dbc'></i> ";
        entry.icons.connectorConnection =
          entry.stats.connectedConnectors == entry.stats.connectors
            ? "<i class='fa fa fa-link text-success'></i> "
            : "<i class='fa fa fa-unlink text-danger'></i> ";
        entry.icons.deviceConnection =
          entry.stats.connectedDevices == entry.stats.devices
            ? "<i class='fa fa fa-link text-success'></i> "
            : "<i class='fa fa fa-unlink text-danger'></i> ";
        value = this.$utils.evaluate(entry, field?.aggregation?.expression);
      }
      return value;
    },
    groupsUpdated(treeItems) {
      if (!treeItems) return;
      let entry = {};
      let connectors = [];
      const _calcLeaf = (lst) => {
        let result = stats();
        let nConnectors = 0;
        (lst || []).forEach((item) => {
          result.stats.enabledDevices += item?.device?.enabled ? 1 : 0;
          result.stats.connectedDevices += item?.device?.is_connected ? 1 : 0;
          result.stats.disconnectedDevices += item?.device?.is_connected
            ? 0
            : 1;
          result.stats.alarmedDevices += item?.device?.has_active_alarms
            ? 1
            : 0;
          if (connectors.indexOf(item.id) == -1) {
            connectors.push(item.id);
            result.stats.enabledConnectors += item.enabled ? 1 : 0;
            result.stats.connectedConnectors += item.is_connected ? 1 : 0;
            result.stats.disconnectedConnectors += item.is_connected ? 0 : 1;
            result.stats.alarmedConnectors += item.has_active_alarms ? 1 : 0;
            nConnectors += 1;
          }
        });
        result.stats.connectors = nConnectors;
        result.stats.devices = (lst || []).length;
        // the connector option can only be available if group results in a single connector
        if (result.stats.devices && result.stats.connectors == 1) {
          result.connector = lst[0];
        }
        return result;
      };

      const _calcParent = (node, result) => {
        entry[node.name] = entry[node.name] || stats();
        for (var k in entry[node.name].stats) {
          entry[node.name].stats[k] += result.stats[k];
        }
        if (node.parent) {
          _calcParent(node.parent, entry[node.name]);
        }
      };

      for (var nodeName in treeItems) {
        let node = treeItems[nodeName];
        if (node?.leaf?.length) {
          let result = _calcLeaf(node.leaf);
          entry[nodeName] = result;
          if (node.parent) {
            _calcParent(node.parent, result);
          }
        }
      }
      this.$set(this, "statsByGroup", entry);
      if (this.isEditing) {
        this.$nextTick(() => {
          this.updatePropertyForm();
        });
      }
    },
    hasEnabledGroupActions(nodeName, field) {
      let actions = field?.aggregation?.on?.click?.actions || [];
      if (actions.length) {
        let groupsWithScreens = (this?.panelOptions?.groupScreenMap || [])
          .filter(({screenId}) => screenId != "")
          .map(({id}) => id);
        for (var i in actions) {
          if ((actions[i]?.type || "")?.startsWith("screen:")) {
            if (actions[i].options.screen_id.value) {
              return true;
            }
            for (var n in groupsWithScreens) {
              if (groupsWithScreens[n].startsWith(nodeName)) {
                return true;
              }
            }
          } else if ((actions[i]?.type || "")?.startsWith("page:")) {
            if (actions[i].options.url.value) {
              return true;
            }
            // TODO: add support to page action by group
          }
        }
      }
      return false;
    },
    triggerEventActions(control, actions) {
      const _trigger = (control, actions) => {
        (actions || []).forEach((action) => {
          this.$root.$emit("custom_action", {
            context: this,
            source: control,
            event: "click",
            connector: null,
            action: action
          });
        });
      };
      if (control?.on?.click?.confirmation?.enabled) {
        this.$swal(control?.on?.click?.confirmation).then(
          (ok) => ok && _trigger(control, actions)
        );
      } else {
        _trigger(control, actions);
      }
    },
    updatePropertyForm() {
      if (this.statsByGroup && this.isEditing) {
        this.$root.$emit("list_table_panel:event", {
          action: "group_list:updated",
          details: {
            group_list: Object.keys(this.statsByGroup)
          }
        });
      }
    },
    onGroupCellClick($event, nodeName, field) {
      if (this.mode == "editor") return;
      if (field?.aggregation?.enabled) return;
      $event.stopPropagation();
      $event.preventDefault();
      let actions = structuredClone(
        field?.aggregation?.on?.click?.actions || []
      );
      const _setGroupScreenId = (actions, groupName) => {
        let status = false;
        let gScreen = (this?.panelOptions?.groupScreenMap || []).find(
          ({id}) => id == groupName
        );
        if (gScreen && gScreen.screenId) {
          for (var i in actions) {
            if (actions[i].type.startsWith("screen:")) {
              actions[i].options = actions[i].options || {};
              actions[i].options.screen_id = actions[i].options.screen_id || {};
              actions[i].options.screen_id.value = gScreen.screenId;
              status = true;
              break;
            }
          }
        } else if (this.statsByGroup[groupName]?.connector?.screen_id) {
          for (var i in actions) {
            if (actions[i].type.startsWith("screen:")) {
              if (
                parseInt(actions[i]?.options?.screen_id?.value) ==
                parseInt(this.statsByGroup[groupName]?.connector?.screen_id)
              ) {
                // since screenid matches the single group connector.screen_id
                // provide the current connector as parameter to open it
                actions[i].options.params = actions[i].options.params || {};
                actions[i].options.params._connector_id = this.statsByGroup[
                  groupName
                ]?.connector.id;
                status = true;
                break;
              }
            }
          }
        }
        return status;
      };
      if (actions.length) {
        // nodeName samples
        //  ["west","west.hospital a","west.hospital b","east","east.hospital a","south"]
        let branch = nodeName.split(".");
        while (branch.length) {
          if (_setGroupScreenId(actions, branch.join("."))) break;
          branch.pop();
        }
        this.triggerEventActions(field?.aggregation, actions);
      }
    },
    onSelectDataCell(item, field) {
      if (this.mode == "editor") return;
      let actions = field?.column?.on?.click?.actions || [];
      if (actions.length) {
        // console.log(actions);
        actions.forEach((action) => {
          if (!action.type.startsWith("screen")) return;
          action.options = action.options || {};
          action.options.params = action.options.params || {};
          if (item.protocol) {
            action.options.params._connector_id = item.id;
            if (item.device) {
              action.options.params._device_id = item.device.id;
            }
          }
        });
        this.triggerEventActions(field?.column, actions);
      } else {
        this.$emit("selectDataCell", {item: item, field: field});
      }
    },
    onIconClicked(item, icon) {
      switch (icon.name) {
        case "connectorEditor":
        case "connectorEnabled":
          this.$router.push(`/dashboard/edit/connector/${item.id}`);
          break;
        case "deviceEditor":
        case "deviceEnabled":
          if (!item.device) {
            this.$router.push(`/dashboard/edit/connector/${item.id}`);
          } else {
            this.$router.push(
              `/dashboard/edit/connector/${item.id}/device/${item.device.id}`
            );
          }
          break;
        case "connectorAlarmed":
        case "deviceAlarmed":
          this.$router.push(`/dashboard/alarms/${item.id}`);
          break;
      }
    }
  }
};
</script>

<style scoped>
.editor-toolbar {
  /* position: absolute; */
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
}

.editor-toolbar.editing {
  border-top-color: #f39c12;
}

.editor-toolbar > div {
  background-color: white;
}

.icon {
  margin: 0 5px;
}

.icon:hover {
  cursor: pointer;
  opacity: 0.8;
  text-shadow: 0px 0px 3px gray;
}

.cell-text {
  text-align: left;
}

.cell-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.group-status-info {
  font-size: 75%;
  padding: 0 10px;
}

.action-group:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #337ab7;
}
</style>
